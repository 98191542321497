<template>
    <body style="position: relative;">
        <div id="wrap">
            <section>
                <headerLayout></headerLayout>
            </section>

            <!-- CONTENTS START -->
            <main>
                <section class="sub-visual">
                    <div class="innWrap">
                        <div class="txt">
                            <h2>변경</h2>
                            <p>※ 기타 변경 사항은 담당 영업 및 고객센터(1588-0114)를 통해 문의하시기 바랍니다.</p>
                        </div>
                        <div class="location">
                            <ul>
                                <li><a>ORDER</a></li>
                                <li><a>서비스 신청</a></li>
                                <li><span>변경</span></li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section class="sub-section svcmodfi">
                    <div class="innWrap">

                        <div class="lineBox">

                            <!-- 검색 START -->
                            <div class="searchBox-select">
                                <div class="select select-inner w288px" id="svcmodfi-title-01">
                                    <span class="placeholder">
                                        {{ selectedSearchTypeTitle }}
                                    </span>
                                    <div class="list">
                                        <ul v-for="item in searchType" :key="item.typeCd" :data-name="item.title">
                                            <li :value="item.typeCd" @click="selectValue($event, 'searchType')">
                                                {{ item.title }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    id="svcmodfi-title-01"
                                    placeholder="입력하세요"
                                    class="input-line"
                                    v-show="selectedSearchTypeCd == '8'"
                                    v-model="partyIdNumber1"
                                    maxlength="10"
                                    readonly
                                    />
                                <input
                                    type="text"
                                    id="svcmodfi-title-02"
                                    placeholder="입력하세요"
                                    class="input-line"
                                    v-show="selectedSearchTypeCd == '3'"
                                    v-model="partyIdNumber2"
                                    maxlength="13"
                                    readonly
                                    />
                                <a @click="getUserSvcInfo()" class="btn-md01 btn-primary">검색</a>
                            </div>
                            <!-- 검색 END -->

                            <!-- LIST START -->
                            <table class="tbl_type02 checkTbl mgt50">
                                <colgroup>
                                    <col width="4%" />
                                    <col width="10%" />
                                    <col width="17%" />
                                    <col width="18%" />
                                    <col width="10%" />
                                    <col width="10%" />
                                    <col width="*" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>선택</th>
                                        <th>고객 ID</th>
                                        <th>고객명</th>
                                        <th>상품명</th>
                                        <th>서비스계약번호</th>
                                        <th>회선번호</th>
                                        <th>설치장소</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="!userSvcInfo">
                                        <td colspan="7">
                                        조회된 데이터가 없습니다.
                                        </td>
                                    </tr>
                                    <tr v-show="userSvcInfo" v-for="item in userSvcInfo" :key="item.scn">
                                        <td>
                                        <div class="checkbox tbl-ckRadio">
                                            <input type="radio" :id="item.scn" class="hide" name="ckRadio" :value="item" :data-name="item.svctypename" @click="selectData(item)"/>
                                            <label :for="item.scn"></label>
                                        </div>
                                        </td>
                                        <td>{{ item.custid }}</td>
                                        <td>{{ item.custname }}</td>
                                        <td>{{ item.svctypename }}</td>
                                        <td>{{ item.scn }}</td>
                                        <td>{{ item.llnum == '' ? '-' : item.llnum}}</td>
                                        <td>{{ item.addr + item.detailaddr}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- LIST END -->

                            <!-- 서비스 정보 START -->
                            <h4 v-show="dataSel != ''">서비스 정보</h4>
                            <table class="tbl_type01" v-show="dataSel != ''">
                                <tbody>
                                    <colgroup>
                                        <col style="width: 200px;" />
                                        <col style="" />
                                        <col style="width: 120px;" />
                                    </colgroup>
                                    <tr>
                                        <th>설치장소</th>
                                        <td>
                                            <span class="">{{ dataSel.addressNoMaking }}</span>
                                        </td>
                                        <td class="agc">
                                            <a v-show="!(dataSel.svc_type_cd == '11' || dataSel.svc_type_cd == '20' || dataSel.svc_type_cd == '30')" 
                                                @click="openPopup('locationInfo')" class="btn-xs btn-pink pop-open">변경</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>속도</th>
                                        <td>
                                            {{ statusListClone.linespecnm == '' ? dataSel.speedcodedesc : statusListClone.linespecnm}}
                                        </td>
                                        <td class="agc">
                                            <a v-show="dataSel.svc_type_cd == '11' || dataSel.svc_type_cd == '20' || dataSel.svc_type_cd == '30'"
                                                @click="openPopup('basicSvcInfo')" class="btn-xs btn-pink pop-open">변경</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>추가 IP</th>
                                        <td>
                                            {{ statusListClone.addipcnt == '' ? (dataSel.addservices.length == 0 ? '없음' : dataSel.addservices[0].fixedipcnt + "개") : (statusListClone.addipcnt == null ? "없음" :statusListClone.addipcnt + "개")}}
                                        </td>
                                        <td class="agc">
                                            <a v-show="dataSel.svc_type_cd == '11' || dataSel.svc_type_cd == '20' || dataSel.svc_type_cd == '30'"
                                            @click="openPopup('basicSvcInfo')" class="btn-xs btn-pink pop-open">변경</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>부가서비스</th>
                                        <td>
                                            <!-- {{ statusListClone.subsacd == '' ? (typeof dataSel.addservices[0] == 'undefined' ? '없음' : dataSel.addservices[0].addsvctypedesc + '  ' + dataSel.speedcodedesc ) : (statusListClone.subsacd == null || statusListClone.subsacd == '' ? '없음' : 'Cloud Direct ('+statusListClone.subsanm+') '+ statusListClone.speedcodedesc+'')}} -->
                                            {{ statusListClone.subsacd == '' ? (dataSel.addservices.length == 0 ? '없음' : dataSel.addservices[0].addsvctypedesc + '  ' + dataSel.addservices[0].addsvcspeeddesc ) : (statusListClone.subsacd == null || statusListClone.subsacd == '' ? '없음' : statusListClone.subsanm +' '+ statusListClone.speedcodedesc )}}
                                        </td>
                                        <td class="agc">
                                            <a v-show="dataSel.svc_type_cd == '11' || dataSel.svc_type_cd == '20' || dataSel.svc_type_cd == '30'"
                                            @click="openPopup('addSvcInfo')" class="btn-xs btn-pink pop-open">변경</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- 서비스 정보 END -->

                            <!-- 변경 희망일 START -->
                            <h4 v-show="dataSel != ''">즉시변경</h4>
                            <table class="tbl_type01" v-show="dataSel != ''">
                                <tbody>
                                <colgroup>
                                    <col style="width: 200px;" />
                                    <col style="" />
                                    <col style="" />
                                </colgroup>
                                <tr>
                                    <th>변경 희망일</th>
                                    <td>
                                        <date-picker v-model="completedResvDateHh"
                                        :lang="lang"
                                        :disabled-date="disabledStartDate"
                                        format="YYYY/MM/DD"
                                        valueType="YYYY/MM/DD" type="date"></date-picker>
                                    </td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                            <p class="asterisk_red" v-show="dataSel != ''">※ 변경 희망일은 최소 <span>7일</span> 전에 요청 바랍니다</p>
                            <!-- 변경 희망일 END -->
                            
                            <!-- 예약변경 (속도) START -->
                            <h4 v-show="termCntrctChgGap != 0">예약변경 (속도)</h4>
                            <table class="tbl_type01" v-show="termCntrctChgGap != 0">
                                <tbody>
                                <colgroup>
                                    <col style="width: 200px;" />
                                    <col style="" />
                                    <col style="" />
                                </colgroup>
                                <tr>
                                    <th>변경 적용 기간</th>
                                    <td>
                                        <date-picker v-model="completedResvDateHh"
                                        :lang="lang"
                                        :disabled-date="disabledStartDate"
                                        format="YYYY/MM/DD"
                                        valueType="YYYY/MM/DD" type="date"></date-picker>
                                    </td>
                                    <td>
                                        ~
                                        <date-picker v-model="cntrctEndResvDate"
                                        :lang="lang"
                                        :disabled-date="disabledEndtDate"
                                        :disabled="disabledEnd"
                                        format="YYYY/MM/DD"
                                        valueType="YYYY/MM/DD" type="date"></date-picker>
                                        
                                        <div class="checkbox">
                                            <input type="checkbox" id="svc-check1" class="hide" v-model="cntrctEndResvDateCheck" :value="cntrctEndResvDateCheck"/>
                                            <label for="svc-check1">미지정</label>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <p class="asterisk_red" v-show="termCntrctChgGap != 0">※ 속도 변경 시에만 예약이 가능하며, 월 2회로 제한됩니다.</p>
                            <br>
                            <p class="asterisk_red" v-show="termCntrctChgGap != 0">※ 변경일 00시 속도가 변경되며, 만료일 익일 00시 원 속도로 변경됩니다.</p>
                            <br>
                            <p class="asterisk_red" v-show="termCntrctChgGap != 0">예) "1/1 ~ 1/5" 선택 시 1/1 ~ 1/5 동안 속도가 변경되며, 1/6 원 속도로 자동 변경</p>
                            <br>
                            <p class="asterisk_red" v-show="termCntrctChgGap != 0">※ 만료일 미지정 시 변경된 속도로 계속 유지됩니다.</p>
                            <br>
                            <p class="asterisk_red" v-show="termCntrctChgGap != 0">예) "1/1 ~ 미지정" 선택 시 1/1 속도 변경</p>
                            <!-- 예약변경 (속도) END -->

                            <!-- 신청자 START -->
                            <h4 v-show="dataSel != ''">신청자</h4>
                            <table class="tbl_type01" v-show="dataSel != ''">
                                <tbody>
                                <tr>
                                    <th style="width: 500px">신청자</th>
                                    <td>

                                    <div class="fcon multi">
                                        <input type="text" placeholder="이름" v-model="comNm" ref="comNm">
                                        <input type="tel" placeholder="연락처" v-model="comTel" ref="comTel">
                                        <input type="email" placeholder="이메일" v-model="comEmail" ref="comEmail">
                                    </div>
                                    
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <!-- 신청자 END -->

                            <!-- 이메일 인증 START -->
                            <div class="lineBox con02 form-list mgt60" v-show="dataSel != ''">
                                <div class="form-Item item01">
                                    <label for="con02-04">인증구분<span class="required">*</span></label>
                                    <div class="fcon">
                                        <div class="radio two multi" ref="appDiv">
                                            <input type="radio" name="division3" id="division3_1" value="01" v-model="appDiv" checked/>
                                            <label for="division3_1" style="margin-left: 0px;">이메일 인증</label>
                                            <input type="radio" name="division3" id="division3_2" value="02" v-model="appDiv"/>
                                            <label for="division3_2">핸드폰 인증</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-Item item01">
                                    <label for="con02-04">인증<span class="required">*</span></label>
                                    <div class="fcon">
                                        <div class="multi mgt20" v-show="appDiv=='01'">
                                            <div class="multi">
                                                <span>이메일 주소</span>
                                                <input type="text" v-model="comEmail" placeholder="kt@kt.com 형식으로 입력" style="width:685px; !important" readonly>
                                                <a class="btn-lg fix btn-primary" v-on:click="sendFlag ? '' : verifySend()">인증번호 발송</a>
                                            </div>
                                        </div>
                                        <div class="multi mgt20" v-show="appDiv=='02'">
                                            <div class="multi">
                                                <span>핸드폰</span>
                                                <input type="text" v-model="comTel" placeholder="01012345678 형식으로 입력" style="width:685px; !important" readonly>
                                                <a class="btn-lg fix btn-primary" v-on:click="sendFlag ? '' : verifySend()">인증번호 발송</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-Item item01">
                                    <label for="con02-04" class="asterisk_red">※ 인증번호 요청 후 3분내 입력 바랍니다</label>
                                    <div class="fcon">
                                        <div class="multi mgt20">
                                            <div class="multi">
                                                <span></span>
                                                <input type="text" id="verifyCheck" v-model="authNum" placeholder="인증번호를 입력해주세요" style="width:685px; !important">
                                                <a class="btn-lg fix btn-primary" v-on:click="authFlag ? '' : verifyCheck()">확인</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 이메일 인증 END -->

                            <!-- 요금 START -->
                            <div class="modfitotal grayBox mgt50" v-show="dataSel">
                                <div class="amount">
                                    <p class="fcPurple2 fw400">
                                        예상 변동 요금 
                                        <span style="font-size: 20px;margin-left: 15px;font-weight: 400;color: #666">(월기준)</span>
                                    </p>
                                    <p v-if="termCntrctChgGap + addsvcCntrctChgGap + useAmtSubGap + uploadCost > 0" style="width: 400px;">
                                        + {{termCntrctChgGap + addsvcCntrctChgGap + useAmtSubGap + uploadCost | comma}}
                                        <span class="fc111">원</span>
                                        <span>(부가세 별도)</span>
                                    </p>
                                    <p v-if="termCntrctChgGap + addsvcCntrctChgGap + useAmtSubGap + uploadCost <= 0" style="width: 400px;">
                                        {{termCntrctChgGap + addsvcCntrctChgGap + useAmtSubGap + uploadCost | comma}}
                                        <span class="fc111">원</span>
                                        <span>(부가세 별도)</span>
                                    </p>
                                    <p class="equals"></p>
                                    <div class="detail">
                                        <dl> 
                                            <dt>기본서비스 (월납)</dt>
                                            <dd v-if="termCntrctChgGap > 0">+ {{termCntrctChgGap | comma}}<span>원</span></dd>
                                            <dd v-if="termCntrctChgGap <= 0">{{termCntrctChgGap | comma}}<span>원</span></dd>
                                        </dl>
                                        <dl class="indent" v-show="uploadCost != 0">
                                            <dt>- 옵션 업로드 속도 증속 (월납)</dt>
                                            <dd><span class="fcPurple3 fw600">{{uploadCost | comma}}</span><span class="fw600">원</span></dd>
                                        </dl>
                                        <dl>
                                            <dt>IP 변경 (월납)</dt>
                                            <dd v-if="addsvcCntrctChgGap > 0">+ {{addsvcCntrctChgGap | comma}}<span>원</span></dd>
                                            <dd v-if="addsvcCntrctChgGap <= 0">{{addsvcCntrctChgGap | comma}}<span>원</span></dd>
                                        </dl>
                                        <dl>
                                            <dt>부가서비스 (월납)</dt>
                                            <dd v-if="useAmtSubGap > 0">+ {{useAmtSubGap | comma}}<span>원</span></dd>
                                            <dd v-if="useAmtSubGap <= 0">{{useAmtSubGap | comma}}<span>원</span></dd>
                                        </dl>
                                        <dl v-show="this.svcTypeSel == 'GO'">
                                            <dt>이전 설치비 (일회성)</dt>
                                            <dd>{{cntrctInstChgAfter | comma}}<span>원</span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <!-- 요금 END -->

                            <!-- BUTTON  START -->
                            <div class="btnBottomArea" v-show="dataSel">
                                <a class="btn-lg fix btn-primary" @click="goOrder()">신청</a>
                            </div>
                            <!-- BUTTON  END -->

                        </div>

                    </div>
                </section>
            </main>
            <!-- CONTENTS END -->

            <!-- POPUP ADDRESS START -->
            <div id="pop-address" class="pop-wrap" v-show="popLocationInfo" style="display:block; !important">
                <div class="pop-inner pop-lg scrollInner-pop">
                    <div class="pop-tit">설치장소
                        <a @click="popLocationInfo=false" class="close pop-close-btn"></a>
                    </div>
                    <div class="pop-con">
                        <div class="pop-address">
                            <label for="pop-address02" class="form-labelh60 w80px agl">주소검색</label>
                            <div class="input-search">
                                <input type="search" id="pop-address02" placeholder="동 이름을 입력하세요." class="w490px" v-model="dong" @keyup.enter="openPopup('addrInfo')" ref="dong">
                                <a @click="openPopup('addrInfo')" class="pop-open"></a>
                            </div>
                            <label for="pop-address03" class="form-labelh60 w100px">건물검색</label>
                            <div class="input-search">
                                <input type="search" id="pop-address03" placeholder="건물 이름을 입력하세요." class="w490px" v-model="bldgname" @keyup.enter="openPopup('buildingInfo')" ref="building">
                                <a @click="openPopup('buildingInfo')" class="pop-open"></a>
                            </div>
                        </div>
                    </div>
                    <div class="pop-tit mgt60">건물조회 결과</div>
                    <div class="pop-con">
                        <div class="tbl_scroll">
                            <table class="tbl_type04">
                                <colgroup>
                                    <col style="width: 240px">
                                    <col style="width: *" />
                                    <col style="width: 167px">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <!-- <th>선택</th> -->
                                        <th>건물명</th>
                                        <th>주소(지번)</th>
                                        <th>건물유형</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="buildingInfo">
                                        <td>{{buildingInfo.bldgname}}</td>
                                        <td>{{`${buildingInfo.addr1}${buildingInfo.addr2} ${detailAddr}`}}</td>
                                        <td>{{buildingInfo.bldgtypenm}}</td>
                                    </tr>
                                    <tr v-show="!buildingInfo">
                                    <td colspan="3" style="text-align: center;">
                                        조회 결과가 없습니다.
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="pop-btnBottomArea">
                        <a @click="closePopup('locationInfo')" class="btn-md01 btn-primary close">확인</a>
                    </div>
                </div>
            </div>
            <!-- POPUP ADDRESS END -->

            <!-- POPUP DONG START -->
            <div id="pop-address02" class="pop-wrap pop-address02" v-show="popAddrInfo" style="display:block; !important">
                <div class="pop-inner pop-md">
                    <div class="pop-tit">
                        주소검색
                        <a @click="popAddrInfo = false" class="close pop-close-btn"></a>
                    </div>
                    <div class="pop-con">
                        <div>
                            <div class="pop-address">
                                <label for="pop-address02-01" class="form-labelh52 w80px agl">주소검색</label>
                                <div class="input-search h52">
                                    <input type="search" id="pop-address02-01" placeholder="동 이름을 입력하세요." class="w500px h52" ref="dong2" v-model="dong" @keyup.enter="getCustAddressList()">
                                    <a @click="getCustAddressList()"></a>
                                </div>
                            </div>
                            <p class="asterisk_bk">※ 검색 결과 선택하시면 자동으로 입력됩니다.</p>
                            <div class="scrollInner-pop">
                                <ul>
                                    <li v-for="item in addrList" :key="item.addrseq" @click="addrInfoTemp(item)"><a>{{item.addr}}</a></li>
                                </ul>
                            </div>
                            <div class="pop-btnBottomArea">
                                <a @click="closePopup('addrInfo')" class="btn-md01 btn-primary close">확인</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- POPUP DONG END -->
            
            <!-- POPUP BUILDING START -->
            <div id="pop-address03" class="pop-wrap pop-address03" v-show="popBuildingInfo" style="display:block; !important">
                <div class="pop-inner pop-md">
                    <div class="pop-tit">
                        건물검색
                        <a @click="popBuildingInfo=false" class="close pop-close-btn"></a>
                    </div>
                    <div class="pop-con">
                        <div>
                            <div class="pop-address">
                                <label for="pop-address03-01" class="form-labelh52 w80px agl">주소</label>
                                <div class="input-search h52">
                                    <input type="text" id="pop-address03-01" v-model="this.addrInfo.addr" class="w500px input-line" readonly>
                                    <a href="#"></a>
                                </div>
                            </div>
                            <div class="pop-address mgt20">
                                <label for="pop-address03-02" class="form-labelh52 w80px agl">건물명</label>
                                <div class="input-search h52">
                                    <input type="search" id="pop-address03-02" v-model="bldgname" placeholder="건물 이름을 입력하세요" class="w500px h52" @keyup.enter="getCustAddressBldgList()">
                                    <a @click="getCustAddressBldgList()"></a>
                                </div>
                            </div>
                            <div class="pop-address mgt20">
                                <label for="pop-address03-03" class="form-labelh52 w80px agl">상세주소</label>
                                <div class="input-search h52">
                                    <input type="search" id="pop-address03-03" v-model="detailAddr" placeholder="상세주소를 입력하세요" class="w500px h52" @keyup.enter="closePopup('buildingInfo')">
                                </div>
                            </div>
                            <p class="asterisk_bk">※ 검색 결과 선택하시면 자동으로 입력됩니다.</p>
                            <div class="scrollInner-pop">
                                <ul>
                                    <li v-for="item in buildingList" :key="item.bldgscode" @click="buildingInfoTemp(item)">{{item.addr1 + item.addr2}}</li>
                                </ul>
                            </div>
                            <div class="pop-btnBottomArea">
                                <a class="btn-md01 btn-primary close" @click="closePopup('buildingInfo')">확인</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- POPUP BUILDING END -->

            <!-- POPUP BASIC INFO START -->
            <div id="pop-basicSvcInfo" class="pop-wrap pop-basicSvcInfo" style="display: block;" v-show="popBasicSvcInfo">
                <div class="pop-inner pop-lg scrollInner-pop">
                    <div class="pop-tit">기본 서비스 정보
                        <a @click="popBasicSvcInfo=false" class="close pop-close-btn"></a>
                    </div>
                    <div class="pop-con">
                        <div class="form-list">
                            <div class="form-Item item01">
                                <label for="">가입유형<span class="required">*</span></label>
                                <div class="fcon multi">
                                    <div class="radio h52 two multi">
                                        <input type="radio" name="svcgender" id="svcgender_1" value="" disabled />
                                        <label for="svcgender_1" class="mgr20">신규</label>
                                        <input type="radio" name="svcgender" id="svcgender_2" value="" checked/>
                                        <label for="svcgender_2">전환</label>
                                    </div>
                                    <div>
                                        <label for="" class="lable_2 form-labelh52" style="margin-left: 30px;">기존 회선번호</label>
                                        <input type="text" placeholder="기존 회선번호" disabled v-model="dataSel.llnum">
                                        <label for="" class="lable_2 form-labelh52" style="margin-left: 30px;">기존 요금</label>
                                        <input type="text" placeholder="기존 요금" disabled :value="typeof statusListClone != 'undefined' ? '' : statusListClone.useamt">
                                    </div>
                                </div>
                            </div>
                            <div class="form-Item item02">
                                <label for="basicSvccon-02">서비스명<span class="required">*</span></label>
                                <div class="fcon multi">
                                    <div class="select w500px disabled" id="basicSvccon-02-01">
                                        <span class="placeholder"><span class="fcGy"></span>{{dataSel.svctypecodedesc}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-Item item03">
                                <label for="basicSvccon-03">속도<span class="required">*</span></label>
                                <div class="fcon1 multi">
                                    <div class="select" id="basicSvccon-03-01">
                                        <span class="placeholder" ref="downSpdSpan"><span class="fcGy"></span>다운로드 속도</span>
                                        <div class="list">
                                            <ul>
                                                <li v-for="item in lineSpeedInfo" :key="item.code_cd" :value="item.code_cd" :data-name="item.code_nm" @click="selectValue($event, 'LS')">{{svcTypeCd=='11'?`최대 `+item.code_nm : item.code_nm}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="select disabled" id="uploadSpdDiv" style="margin-left:32px;">
                                        <span class="placeholder" ref="uploadSpdSpan"><span class="fcGy">업로드 속도</span></span>
                                        <div class="list">
                                            <ul>
                                                <li v-for="item in lineUploadSpeedInfo" :key="item.code_cd" :value="item.code_cd" :data-index="item.displayseq" :data-name="item.code_nm" @click="selectValue($event, 'LSU')" ref="uploadSpdLi">{{svcTypeCd=='11'?`최대 `+item.code_nm : (svcTypeCd=='20' ? (item.code_cd == '80' ? item.code_nm+' (기본)': item.code_nm) : item.code_nm)}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <label id="aa" class="lable_2 form-labelh52" style="margin-left: 20px; margin-right: 5px;">기본 제공 IP</label>
                                <input id="aa" type="text" style="width:104px; text-align:center; margin-left:10px; important!" readonly :value="`${typeof statusListClone == 'undefined' ? '0' : statusListClone.ip_addrcnt}   `">
                                <label for="" class="lable_2 form-labelh52">추가 IP</label>
                                <div class="fcon2 multi">
                                    <div class="input_num">
                                        <span class="btn-minus" @click="getaddIpCntTemp('-')"></span>
                                        <input type="text" id="" placeholder="" readonly v-model="addIpCntTemp" style="background:white;color:black; !important">
                                        <span class="btn-plus" @click="getaddIpCntTemp('+')"></span>
                                    </div>
                                </div>
                            </div>
                            <p class="asterisk_red" style="text-align:left; margin-left:135px; !important">※ Flexline Basic / Premium+ 는 업다운 대칭, GiGAoffice, Flexline Premium 은 업로드 기본 200M 제공합니다.</p>
                            <div class="form-Item item05">
                                <label for="basicSvccon-05">계약기간</label>
                                <div class="fcon multi">
                                    <div class="radio h52 five multi">
                                        <input type="radio" name="term" id="term_1" v-model="dataSel.contractperiodtypecodedesc" value="기간없음" disabled/>
                                        <label for="term_1">무약정</label>
                                        <input type="radio" name="term" id="term_2" v-model="dataSel.contractperiodtypecodedesc" value="2년" disabled/>
                                        <label for="term_2">2년 (10%)</label>
                                        <input type="radio" name="term" id="term_3" v-model="dataSel.contractperiodtypecodedesc" value="3년" disabled/>
                                        <label for="term_3">3년 (15%)</label>
                                        <input type="radio" name="term" id="term_4" v-model="dataSel.contractperiodtypecodedesc" value="4년" disabled/>
                                        <label for="term_4">4년 (20%)</label>
                                        <input type="radio" name="term" id="term_5" v-model="dataSel.contractperiodtypecodedesc" value="5년" disabled/>
                                        <label for="term_5">5년 (25%)</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-Item item06 label_2ea">
                                <label for="basicSvccon-06">협력사</label>
                                <div class="fcon1 multi">
                                    <div class="select disabled" id="basicSvccon-06-01">
                                        <span class="placeholder"><span class="fcGy">{{statusListClone.maintagentnm == null ? '-' : statusListClone.maintagentnm}}</span></span>
                                    </div>
                                </div>
                                <label for="basicSvccon-06" class="lable_2 agc pd0">공급장비</label>
                                <div class="fcon2 multi">
                                    <div class="select disabled" id="basicSvccon-07" style="width:463px;!important">
                                        <span class="placeholder"><span class="fcGy">{{statusListClone.equipgrpnm == null ? '-' : statusListClone.equipgrpnm}}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pop-btnBottomArea">
                        <a @click="closePopup('basicSvcInfo')" class="btn-md01 btn-primary close">확인</a>
                    </div>
                </div>
            </div>
            <!-- POPUP BASIC INFO END -->

            <!-- POPUP ADDSVCINFO START -->
            <div id="pop-addSvcInfo" class="pop-wrap pop-addSvcInfo" v-show="popAddSvcInfo" style="display:block; !important">
                <div class="pop-inner pop-lg scrollInner-pop">
                    <div class="pop-tit">부가 서비스 정보
                        <a @click="popAddSvcInfo=false" class="close pop-close-btn"></a>
                    </div>
                    <div class="pop-con">
                        <div class="form-list">
                            <div class="form-Item item01 label_2ea">
                                <label for="addSvccon-01">Cloud Direct</label>
                                <div class="fcon">
                                    <div class="multi">
                                        <div class="fcon1">
                                        <div class="radio h52 two multi">
                                            <input type="radio" name="cloud" id="cloud_1" value="false" ref="cloudDirectF" @click="cloudDirectChange($event, false)"/>
                                            <label for="cloud_1">선택안함</label>
                                            <input type="radio" name="cloud" id="cloud_2" value="true" ref="cloudDirectT" @click="cloudDirectChange($event, true)"/>
                                            <label for="cloud_2">선택</label>
                                        </div>
                                        </div>
                                    </div>

                                    <div class="multi mgt20" v-show="cloudDirectFlag">
                                        <span class="stit lable_2">제공</span>
                                        <div class="select" id="addSvccon-01-03">
                                            <span class="placeholder" ref="cldSvc"><span class="fcGy">선택하세요</span></span>
                                            <div class="list">
                                            <ul v-for="item in cloudDirectInfo" :key="item.displayseq">
                                                <li :value="item.code_cd" @click="selectValue($event, 'CD')" :data-name="item.code_nm">{{item.code_nm}}</li>
                                            </ul>
                                            </div>
                                        </div>
                                        <span class="stit2 lable_2">속도</span>
                                        <div class="select" id="addSvccon-01-04">
                                            <span class="placeholder" ref="cldSvcSpd"><span class="fcGy">최대 속도</span></span>
                                            <div class="list">
                                            <ul v-for="item in cloudDirectSpeed" :key="item.code_cd">
                                                <li :value="item.code_cd" @click="selectValue($event, 'CS')" :data-name="item.code_nm">{{item.code_nm}}</li>
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="asterisk_bk pdl140">
                                ※ Cloud 연결 제공 가능 서비스 : Flexline Premium / Basic<br />
                                ※ 각 서비스별 최대 속도 범위가 상이할 수 있습니다
                            </p>
                        </div>
                    </div>

                    <div class="pop-btnBottomArea">
                        <a @click="closePopup('addSvcInfo')" class="btn-md01 btn-primary close">확인</a>
                    </div>
                </div>
            </div>
            <!-- POPUP ADDSVCINFO END -->

            <section>
                <FooterLayout></FooterLayout>
            </section>

        </div>
    </body>
</template>
<script>
import moment from 'moment'
import "@/assets/css/jquery.datetimepicker.css"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import HeaderLayout from "./common/Header.vue"
import FooterLayout from './common/Footer.vue'
import { gigaofficeService } from "Api"

export default {
    components: {
        HeaderLayout,
        FooterLayout,
        DatePicker
    },
    data () {
        return {
            completedResvDateHh: '',
            cntrctEndResvDate: '',
            disabledEnd: true,
            cntrctEndResvDateCheck: true,
            lang: {
				days: ["일", "월", "화", "수", "목", "금", "토"],
				months: [
					"1월",
					"2월",
					"3월",
					"4월",
					"5월",
					"6월",
					"7월",
					"8월",
					"9월",
					"10월",
					"11월",
					"12월",
				],
                yearFormat: "YYYY년",
                monthFormat: "MM월",
                monthBeforeYear: false,
			},
            searchType: [
                { title: "사업자등록번호", typeCd: "8" },
                { title: "법인번호", typeCd: "3" }
            ],
            selectedSearchTypeCd: "8",
            selectedSearchTypeTitle: "사업자등록번호",
            ownerContactInfo: '',
            userInfo: '',
            partyIdNumber: '',
            partyIdNumber1: '',
            partyIdNumber2: '',
            encPartyIdNumber1: '',
			encPartyIdNumber2: '',
            userSvcInfo: '',
            // 초기 다운로드 속도
            initDownloadSpcCdTemp: '',
            // 초기 업로드 속도
            initUploadSpcCdTemp: '',
            // 초기 추가 IP 수
            initAddIpCntTemp: 0,
            // 변경된 다운로드 속도
            downloadSpcCdTemp: '',
            // 변경된 업로드 속도
            uploadSpcCdTemp: '',
            addIpCntTemp: 0,
            // 변경된 추가 IP 수
            addIpCnt: 0,
            // 변경된 부가서비스 속도
            bugaSpdCdTemp: '',
            // 원부 데이터
            dataSel : '',
            // 원부 부가서비스
            dataSelAdd : '',
            // 신청내역조회
            statusList: '',
            // DB에 있는 청약데이터
            statusListClone : {},
            // 상품분류코드
            svcTypeSel : '',
            // 서비스 원부 정보
            svcMasterInfo: '',
            // 추가 서비스
            addServices: '',
            // 신청자 이름
            comNm: '',
            // 신청자 연락처
			comTel: '',
            // 신청자 이메일
			comEmail: '',
            // 인증 구분
            appDiv: '01',
            authNum: '',
            authFlag: false,
            sendFlag: false,
            // 총액 기본서비스
            termCntrctChgGap: 0,
            // 예상변동요금 IP
            addsvcCntrctChgGap: 0,
            // 예상변동요금 부가서비스
            useAmtSubGap: 0,
            // 설치비
            cntrctInstChgBefore: 100000,
            // 설치비
            cntrctInstChgAfter: 100000,
            // 장비임대료
            equipUseChgBefore: 0,
            // 기간비용
            termCntrctChgBefore: 0,
            // 상품비용(기간비용+설치비)
            svcTotalChgBefore: 0,
            //상품비용
            // 설치비
            cntrctInstChgTemp: 100000,
            // 장비임대료
            equipUseChgTemp: 0,
            // 기간비용
            termCntrctChgTemp: 0,
            // 상품비용(기간비용+설치비)
            svcTotalChgTemp: 0,
            // 동
            dong: '',
            // 건물
            bldgname: '',
            // 빌딩 정보
            buildingInfo: '',
            // 상세주소
            detailAddr: '',
            // 동 정보
            addrList: '',
            // 빌딩 정보
            buildingList: '',
            // 주소 정보
            addrInfo: '',
            // 주소 선택
            addrInfoSel: '',
            // 빌딩 선택
            buildingInfoSel: '',
            // 주소
            fullAddr: '',
            // 속도 정보
            lineSpeedInfo: '',
            lineUploadSpeedInfo: '',
            lineUploadSpeedInfoCopy: '',
            lineUploadSpeedInfoInit: '',
            svcTypeCd: '',
            cloudDirectInfo: '',
            cloudDirectSpeed: '',
            cloudDirectCostInfo: '',
            // 상품비용(기간비용+설치비)
            svcTotalChgAfter: 0,
            // 추가 IP비용
            addsvcCntrctChg: 0,
            // 기존 추가 IP비용
            addsvcCntrctChgBefore: 0,
            // 추가 IP비용
            addsvcCntrctChgTemp: 0,
            lineSpeedInfoSel: '',
            lineSpeedInfoNmSel: '',
            // 예상변동요금 총
            useAmtGap: 0,
            // 상품 비용 정보
            productCostInfo: '',
            // 상품 정보
            productInfo: '',
            // 추가 IP 정보
            subProductInfo: '',
            cloudDirectInfoSel: '',
            cloudDirectSpeedSel: '',
            lineSpeedInfoTemp: '',
            lineSpeedInfoNmTemp: '',
            lineSpeedInfoCal: '',
            uploadSpeedInfoTemp: '',
            uploadSpeedNmTemp: '',
            uploadSpeedNmSel: '',
            // 업로드속도 추가에 따른 비용
            uploadCost: 0,
            subSaNm: '',
            // 총비용(설치비+장비임대료)
            svcTotalChgSubTemp: 0,
            // 기존금액
            useAmtSubBefore: 0,
            useAmtTemp: 0,
            partyDetailTypeCd: '',
            // POPUP 주소
            popLocationInfo: false,
            // POPUP 동
            popAddrInfo: false,
            // POPUP 빌딩
            popBuildingInfo: false,
            // POPUP 기본정보
            popBasicSvcInfo: false,
            // POPUP 부가서비스
            popAddSvcInfo: false,
            // 클라우드
            cloudDirectFlag: false,
            cntrctInstChgSubTemp: 0,
            equipUseChgSubTemp: 0,
            timeZone: 9 * 60 * 60 * 1000,
            timeInterval: '',
            seq: 0,
            reservationLineSpecCd: '',
            reservationLineSpecInfoNmSel: '',
            reservationUploadSpd: '',
            reservationUploadSpdInfoNmSel: '',
            reservationI: 2,
            custNoType: '3',
            cdmCustNoType: '3',
            custNoTypeNm: '법인번호',
        }
    },
    mounted: async function() {
        this.getOwnerContactInfoByTokenId()

        await this.dateInit()

        await this.getCloudDirectList()
        await this.goProductCostInfo()
        await this.goProductInfo()
        await this.getGoSubProductInfo()
        await this.getCloudDirectSpeedList()
    },
    watch: {
        completedResvDateHh (date) {
            if(moment(date).format("YYYYMMDD") > moment(this.cntrctEndResvDate).format("YYYYMMDD")){
                this.cntrctEndResvDate = date
            }
        },
        cntrctEndResvDateCheck () {
            if(this.cntrctEndResvDateCheck){
                this.disabledEnd = true;
            }else{
                this.disabledEnd = false;
            }
        }
    },
    methods: {
        async dateInit () {
            let date = moment().add("7", "days").startOf("day").format("YYYY/MM/DD")
            const temp = new Date(date).getDay()
            if(temp == 0){
                this.completedResvDateHh = moment().add("8", "days").startOf("day").format("YYYY/MM/DD")
                this.cntrctEndResvDate = moment().add("8", "days").startOf("day").format("YYYY/MM/DD")
            }else if(temp == 6){
                this.completedResvDateHh = moment().add("9", "days").startOf("day").format("YYYY/MM/DD")
                this.cntrctEndResvDate = moment().add("9", "days").startOf("day").format("YYYY/MM/DD")
            }else{
                this.completedResvDateHh = moment().add("7", "days").startOf("day").format("YYYY/MM/DD")
                this.cntrctEndResvDate = moment().add("7", "days").startOf("day").format("YYYY/MM/DD")
            }
        },
        disabledStartDate(date) {
			// 휴일
			const arr = ['9/23, 12/25', '1/1', '10/5']
			const monthh = date.getMonth()
			const dayy = date.getDate();
			const holiday = arr.some(v => v==`${monthh}/${dayy}`)
			
			const day = new Date(date).getDay()

			return holiday || (day === 0 || day === 6) || (moment(date).format("YYYYMMDD") < moment().add("7", "days").startOf("day").format("YYYYMMDD"))
		},
        disabledEndtDate(date) {
			// 휴일
			const arr = ['9/23, 12/25', '1/1', '10/5']
			const monthh = date.getMonth()
			const dayy = date.getDate();
			const holiday = arr.some(v => v==`${monthh}/${dayy}`)
			
			const day = new Date(date).getDay()

            let min
            if(this.completedResvDateHh != ''){
                let startTime = new Date(this.completedResvDateHh)
                let minDate = new Date(startTime.setDate(startTime.getDate() - 1))
                min = date <= minDate
            }

			return holiday || (day === 0 || day === 6) || (moment(date).format("YYYYMMDD") < moment().add("3", "days").startOf("day").format("YYYYMMDD")) || min
		},
        //1-1. 토큰으로 사업자번호/법인번호 조회
        async getOwnerContactInfoByTokenId() {
            let token_id = sessionStorage.getItem("accessToken");
            let param = { token_id: token_id };
            await this.$store.dispatch("auth/getOwnerContactInfoByTokenId", param).then(res => {
                this.ownerContactInfo = res;
                this.getUserInfo()
                // 3 4 6 11 20 30 40 50 70
                // if (this.ownerContactInfo.partyDetailTypeCd == '03' || this.ownerContactInfo.partyDetailTypeCd == '06') {
                //     this.partyDetailTypeCd = '3'
                // }else if (this.ownerContactInfo.partyDetailTypeCd == '08') {
                //     this.partyDetailTypeCd = '8'
                // }
                this.partyDetailTypeCd = this.ownerContactInfo.partyDetailTypeCd;
                if (sessionStorage.getItem("partyTypeId") == "01") {
                    this.partyIdNumber = this.ownerContactInfo.partyIdNumber1;
                } else {
                    this.partyIdNumber = this.ownerContactInfo.partyIdNumber2;
                }
                this.partyIdNumber1 = this.ownerContactInfo.partyIdNumber1
                this.encPartyIdNumber1 = this.ownerContactInfo.encPartyIdNumber1
                this.partyIdNumber2 = this.ownerContactInfo.partyIdNumber2
                this.encPartyIdNumber2 = this.ownerContactInfo.encPartyIdNumber2
            })
            .catch(error => {
                console.log(error);
            })
        },
        // 고객 정보 조회
		getUserInfo: async function() {
			let param = {}
			await this.$store.dispatch("gigaoffice/getUserInfo", param).then((res) => {
                if(res.data.code != '100') {
                    return false
                }
				this.userInfo = res.data.result
                this.cdmCustNoType = this.userInfo[0].custTypeCd
                if(this.cdmCustNoType === '3'){
                    this.custNoType = '3'
                    this.custNoTypeNm = '법인번호'
                }else if(this.cdmCustNoType === '4' || this.cdmCustNoType === '10'){
                    this.custNoType = '4'
                    this.custNoTypeNm = '관청'
                }else if(this.cdmCustNoType === '2' || this.cdmCustNoType === '8'){
                    this.custNoType = '8'
                    this.custNoTypeNm = '사업자번호'
                }else if(this.cdmCustNoType === '9'){
                    this.custNoType = '9'
                    this.custNoTypeNm = '기타'
                }else if(this.cdmCustNoType === '5'){
                    this.custNoType = 'B'
                    this.custNoTypeNm = '임의단체'
                }else if(this.cdmCustNoType === '6'){
                    this.custNoType = 'C'
                    this.custNoTypeNm = '해외사업자번호'
                }
			})
			.catch(error => { 
				console.log(error)
			})
		},
        async getUserSvcInfo() {
            let param = {} ,res1 ,res2 ,temp1 ,temp2 ,temp = [] ,key = 'scn';
            this.dataSel = ''
            this.statusListClone = ''
            if(this.partyIdNumber == "01" && (this.partyIdNumber1 == '' || this.partyIdNumber1 == null)) {
                alert("조회 정보가 입력 되지 않았습니다. 사업자등록번호 또는 법인번호를 입력해주세요.");
                return false;
            }
            if (this.partyIdNumber == "02" && (this.partyIdNumber2 == "" || this.partyIdNumber2 == null)) {
                alert( "조회 정보가 입력 되지 않았습니다. 사업자등록번호 또는 법인번호를 입력해주세요.");
                return false;
            }
            this.selectedSearchTypeCd == "8" ? (this.custType = "8") : (this.custType = "3");
            // this.selectedSearchTypeCd == "8" ? (this.custNum = this.partyIdNumber1.replace(/-/gi, "")) : (this.custNum = this.partyIdNumber2.replace(/-/gi, ""));
            this.selectedSearchTypeCd == "8" ? (this.custNum = this.encPartyIdNumber1) : (this.custNum = this.encPartyIdNumber2);
            param = { 
                custType: this.custType
                , custNum: this.custNum
            };
            res1 = await gigaofficeService.getUserSvcInfo(param);
            temp1 = res1.data.result

            this.selectedSearchTypeCd == "8" ? (this.custType = "3") : (this.custType = "8");
            // this.selectedSearchTypeCd == "8" ? (this.custNum = this.partyIdNumber2.replace(/-/gi, "")) : (this.custNum = this.partyIdNumber1.replace(/-/gi, ""));
            this.selectedSearchTypeCd == "8" ? (this.custNum = this.encPartyIdNumber2) : (this.custNum = this.encPartyIdNumber1);
            param = { 
                custType: this.custType
                , custNum: this.custNum
            };
            res2 = await gigaofficeService.getUserSvcInfo(param);
            temp2 = res2.data.result
            temp1.forEach(function(value ,index){
                temp.push(value)
            })
            temp2.forEach(function(value ,index){
                temp.push(value)
            })
            temp = temp.reduce((i ,item) => {
                const removed = i.filter(j => j[key] !== item[key])
                return [...removed ,item]
            }, [])
            this.userSvcInfo = temp
        },
        async selectValue(e, type) {
			let v = e.currentTarget.getAttribute('value')
			let vnm = e.currentTarget.getAttribute('data-name')
			let i = e.currentTarget.getAttribute('data-index')
            let element = document.getElementById('uploadSpdDiv')

            switch (type) {
                case 'searchType' :
                    this.selectedSearchTypeCd = v
                    this.selectedSearchTypeTitle = vnm
                    break
                case 'CD' :
                    this.cloudDirectInfoSel = v
                    this.getCloudDirectCostInfo()
                    this.subSaNm = vnm
                    break
                case 'LS' :
                    element.classList.add('disabled')
                    // console.log('v : ', v)
                    // console.log('vnm : ', vnm)
                    this.lineSpeedInfoTemp = v
                    this.lineSpeedInfoNmTemp = vnm
                    this.useAmtTemp = 0
                    this.uploadSpeedInfoTemp = v

                    if(this.svcTypeCd == '11') {
                        this.$refs.uploadSpdSpan.innerHTML = `최대 ${vnm}`
                    } else if(this.svcTypeCd == '20') {
                        element.classList.remove('disabled')
                        //업로드 속도 제한
                        const lineUploadSpeedInfoInit = JSON.parse(JSON.stringify(this.lineSpeedInfo))
                        let lsDisplayseq = lineUploadSpeedInfoInit.find(item => item.code_cd == this.lineSpeedInfoTemp).displayseq
                        const lineUploadSpeedInfoCopy = lineUploadSpeedInfoInit.filter(item => item.displayseq <= lsDisplayseq)
                        this.lineUploadSpeedInfo = lineUploadSpeedInfoCopy
                        this.$refs.uploadSpdSpan.innerHTML = `<span class="">200M (기본)</span>`
                        this.uploadSpeedInfoTemp = '80'
                        this.uploadSpeedNmTemp = `200M`
                        this.uploadCost = (2-this.reservationI) * 100000

                    } else if (this.svcTypeCd == '30') {
                        this.$refs.uploadSpdSpan.innerHTML = `${vnm}`

                        if(v == '3A' || v == '80' || v == '85' || v == '90'){
                            this.basicIpCnt = 32
                        }else{
                            this.basicIpCnt = 128
                        }
                    }

                    this.addIpCnt = 0
                    this.addIpCntTemp = 0

                    this.getCost()
                    this.getAddIpCost()
                    break
                case 'LSU' :
                    this.uploadCost = 0
					this.uploadSpeedInfoTemp = v
					this.uploadSpeedNmTemp = `${vnm}`
					if(this.svcTypeCd == '20') {
                        this.uploadCost = (i-this.reservationI) * 100000 
					}
					this.getCost()
					break;
                case 'CS' :
                    this.cloudDirectSpeedSel = v
                    this.cloudDirectSpeedNmSel = vnm
                    this.tempSpeedCodeDesc = e.target.innerText
                    this.getSubCost()
                    break
            }
        },
        // 변수 초기화
        init() {
            // 변경된 다운로드 속도
            this.downloadSpcCdTemp = ''
            // 변경된 업로드 속도
            this.uploadSpcCdTemp = ''
            // 변경된 추가 IP 수
            this.addIpCnt = 0
            // 변경된 부가서비스 속도
            this.bugaSpdCdTemp = ''

            // 원부 데이터
            this.dataSel = ''
            // 원부 부가서비스
            this.dataSelAdd = ''
            // DB에 있는 청약데이터
            this.statusListClone = {}

            this.comNm = ''
			this.comTel = ''
			this.comEmail = ''
            this.appDiv = '01'
            this.authNum = ''
            this.sendFlag = false
            this.authFlag = false

            this.termCntrctChgGap = 0
            this.addsvcCntrctChgGap = 0
            this.useAmtSubGap = 0
            this.cntrctInstChgAfter = 100000

            this.cntrctInstChgSubTemp = 0
            this.equipUseChgSubTemp = 0

            this.uploadCost = 0

        },
        // 서비스명 변경 시 서비스명, 속도, IP 초기 값 세팅
		async setData(type) {
            // 신규 신청 업로드 속도 data-index
            this.reservationI = 2

            // 상품금액
            if(typeof this.statusList[0] == 'undefined') {
                this.statusListClone = {}
                this.statusListClone.addipcnt = ''
                this.statusListClone.ip_addrcnt = ''
                this.statusListClone.linespeccd = ''
                this.statusListClone.linespecnm = ''
                this.statusListClone.uploadspd = ''
                this.statusListClone.uploadspdnm = ''
                this.statusListClone.bugauseamt = ''
                this.statusListClone.subsacd = ''
                this.statusListClone.subsanm = ''
                this.statusListClone.bugaspdcd = ''
                this.statusListClone.speedcodedesc = ''
                this.statusListClone.svctypenm = ''
                this.statusListClone.termcntrcttypecd = ''
                this.statusListClone.maintagentcd = ''
                this.statusListClone.sellerinfo = ''
                this.statusListClone.useamt = ''
            } else {
                this.statusListClone = JSON.parse(JSON.stringify(this.statusList[0]))
            }

            this.addIpCntTemp = this.statusListClone.addipcnt == null || this.statusListClone.addipcnt == '' ? 0 : this.statusListClone.addipcnt 
            // this.addIpCntTemp = this.dataSel.addservices[0].fixedipcnt
            // this.useAmtSubBefore = typeof this.statusList[0] == 'undefined' ? 0 : this.statusList[0].bugauseamt 
            // console.log('this.statusListClone.linespecnm : ', this.statusListClone.linespecnm)
            // console.log('this.dataSel .linespecnm : ', this.dataSel.speedcodedesc)
            // console.log('this.statusListClone.uploadspdnm : ', this.statusListClone.uploadspdnm)
            // console.log('this.dataSel .uploadspdnm : ', this.dataSel.uploadspeeddesc)

            let linespecnmtemp ,uploadspdnmtemp
            if(this.statusListClone.linespecnm == ''){
                linespecnmtemp = this.dataSel.speedcodedesc
            }
            if(this.statusListClone.linespecnm == ''){
                uploadspdnmtemp = this.dataSel.uploadspeeddesc
            }

            this.$refs.downSpdSpan.innerHTML = `<span class="">${this.svcTypeCd == 11? '최대 ' + linespecnmtemp : linespecnmtemp}</span>` 
            this.$refs.uploadSpdSpan.innerHTML = `<span class="">${this.svcTypeCd == 11? '최대 ' + uploadspdnmtemp : uploadspdnmtemp}</span>`

            this.reservationLineSpecCd = this.dataSel.line_spec_cd
            this.reservationLineSpecInfoNmSel = this.dataSel.speedcodedesc
            this.reservationUploadSpd = this.dataSel.upload_spec_cd
            this.reservationUploadSpdInfoNmSel = this.dataSel.uploadspeeddesc

            if(this.dataSel.addservices.length > 0){
                document.querySelector('#cloud_2').click()
                
                this.cloudDirectInfoSel = this.statusListClone.subsacd
                // this.subSaNm = this.statusListClone.subsanm.replace(')' ,'').replace('Cloud Direct (','')
                this.subSaNm = this.dataSel.addservices[0].addsvctypedesc.replace(')' ,'').replace('Cloud Direct (','')
                this.$refs.cldSvc.innerHTML = `<span class="">` + this.subSaNm + `</span>`
                
                this.cloudDirectSpeedNmSel = this.dataSel.addservices[0].addsvcspeeddesc
                this.$refs.cldSvcSpd.innerHTML = `<span class="">` + this.cloudDirectSpeedNmSel + `</span>`
                
                // console.log('this.cloudDirectSpeedNmSel : ', this.cloudDirectSpeedNmSel)
                // this.cloudDirectSpeedSel = this.statusListClone.bugaspdcd
                if(this.cloudDirectSpeed != ''){
                    this.cloudDirectSpeed.forEach(item => {
                        if(item.code_nm == this.cloudDirectSpeedNmSel){
                            this.cloudDirectSpeedSel = item.code_cd
                        }
                    })
                }

                if(this.cloudDirectInfo != ''){
                    this.cloudDirectInfo.forEach(item => {
                        if(item.code_nm == this.subSaNm){
                            this.cloudDirectInfoSel = item.code_cd
                        }
                    })
                }

                await this.getCloudDirectCostInfo()
                // console.log('this.cloudDirectSpeedSel : ', this.cloudDirectSpeedSel)
                // console.log('this.dataSel : ', this.dataSel)
                if(this.cloudDirectCostInfo != ''){
                    this.cloudDirectCostInfo.forEach(item => {
                        // console.log('item : ', item)
                        if(item.lineSpecCd == this.cloudDirectSpeedSel && item.bgSvcTypeCd == this.cloudDirectInfoSel){
                            // console.log('item.equipUseChg : ', item.equipUseChg)
                            this.useAmtSubBefore = parseInt(item.equipUseChg)
                        }
                    })
                }
            }else{
                document.querySelector('#cloud_1').click()
            }

            if(this.lineSpeedInfo != '' && this.reservationUploadSpd != ''){
                const lineUploadSpeedInfoInit = JSON.parse(JSON.stringify(this.lineSpeedInfo))
                
                lineUploadSpeedInfoInit.forEach(item => {
                    if(item.code_cd == this.reservationUploadSpd){
                        this.reservationI = item.displayseq
                    }
                })
                // console.log('reservationI : ', this.reservationI)
            }

            if(type == 'init') {
                this.lineSpeedInfoTemp = this.dataSel.line_spec_cd
                this.lineSpeedInfoNmTemp = this.dataSel.speedcodedesc
                this.uploadSpeedInfoTemp = this.statusListClone.uploadspd
                this.uploadSpeedNmTemp = this.statusListClone.uploadspdnm

                this.initDownloadSpcCdTemp = this.dataSel.line_spec_cd
                this.initUploadSpcCdTemp = this.statusListClone.uploadspd == '' || this.statusListClone.uploadspd == undefined ? this.dataSel.line_spec_cd : this.statusListClone.uploadspd
            }

            if(this.dataSel.svc_type_cd == '11') {
                this.$refs.cloudDirectT.disabled = false
                this.$refs.cloudDirectF.disabled = false
            } else if (this.dataSel.svc_type_cd == '20') {
                // Premium은 속도 비대칭
                this.$refs.cloudDirectT.disabled = false
                this.$refs.cloudDirectF.disabled = false
                this.$refs.uploadSpdSpan.disabled = false
                
                // 업로드 속도 제한
                if(type=='init') {
                    // 기존 추가 IP 개수
                    if(typeof this.statusList[0] == 'undefined') {
                        this.addIpCnt_before = 0
                    } else {
                        this.addipcnt_before = this.statusList[0].addipcnt
                    }
                    //기본 200M
                    // this.uploadSpeedInfo_sel = '80'
                    // this.$refs.uploadSpdSpan.innerHTML = `<span class="">200M</span>`
                }
            } else {
                this.cloudDirectFlag = false
                this.cloudDirectInfoSel = ''
                this.cloudDirectSpeedSel = ''
                this.$refs.cloudDirectT.disabled = true
                this.$refs.cloudDirectF.disabled = true
            }
			this.getAddIpCost()
			this.getCost()
		},
        async selectData(obj) {
            await this.dateInit()

            this.init()
            this.svcTypeSel = obj.svctypename.indexOf('GiGA') == -1 ? 'FL' : 'GO'

            await this.searchList(obj.encCustId, obj.encScn)
            if(obj.svctypename.indexOf('Basic') == '-1') {
                await this.getSvcMasterInfo(obj)
            } else {
                await this.getSvcMasterInfo2(obj)
            }

            await this.flexLineContractInfo(obj.encScn)
            this.dataSel = JSON.parse(JSON.stringify(this.svcMasterInfo))

            // console.log('dataSel : ', this.dataSel)
            
            this.dataSelAdd = this.svcMasterInfo.addservices.length != 0 ? JSON.parse(JSON.stringify((this.svcMasterInfo.addservices[0]))) : ''
            this.dataSel.addressNoMaking = this.dataSel.addressNoMaking.replaceAll('^', ' ')
            // this.svcMasterInfo = ''
            // this.addServices = ''

            this.setData('init')
            await this.getCost('init')
        },
        //신청현황데이터(원부에 없는 데이터 가져오기 위함)
        async searchList(custid, scn) {
            let param = {
                tokenId : sessionStorage.getItem('accessToken')
                , userId: sessionStorage.getItem('userId')
                , custInd: custid
                , type: '01'
            }
            //신청내역조회 api호출
            await this.$store.dispatch('gigaoffice/getOrderStatusList', param).then((res) => {
                var result = res.data.response.list.filter(item => item.trtmstatcd == '3' && item.said == scn)
                if(result.length == 0) {
                    this.statusList  = {}
                } else {
                    this.statusList = result
                }
            })
            .catch(error => {
                console.log(error)
            })
        },
        //Flexline Basic 이외상품
        async getSvcMasterInfo(obj) {
            let param = { 
                scn: obj.encScn
                , custId: obj.encCustId
            };
            //api호출
            let res = await gigaofficeService.getSvcMasterInfo(param);
            if (res.data.code == "100") {
                this.svcMasterInfo = res.data.result;
                // console.log('getSvcMasterInfo this.svcMasterInfo : ', this.svcMasterInfo)
                if(this.svcTypeSel == 'FL') await this.getLineSpeedList(this.svcMasterInfo.svc_type_cd)
            } else if (res.data.code == "9250") {
                alert("조회된 데이터가 없습니다.");
            } else {
                alert(res.data.message);
            }
        },
        //Flexline Basic 상품
        async getSvcMasterInfo2(obj) {
            let param = { 
                scn: obj.encScn
                , custId: obj.encCustId
            };
            //api호출
            let res = await gigaofficeService.getSvcFlexlineMasterInfo(param);
            if (res.data.code == "100") {
                this.svcMasterInfo = res.data.result;
                // console.log('getSvcMasterInfo2 this.svcMasterInfo : ', this.svcMasterInfo)
                if(this.svcTypeSel == 'FL') await this.getLineSpeedList(this.svcMasterInfo.svc_type_cd)
            } else if (res.data.code == "9250") {
                alert("조회된 데이터가 없습니다.");
            } else {
                alert(res.data.message);
            }
        },
        // Flexline 검증
        async flexLineContractInfo(scn) {
            let param = {
                tokenId : sessionStorage.getItem('accessToken')
                , scn: scn
            }
            //Flexline 검증 API 호출
            await this.$store.dispatch('gigaoffice/getFlexlineContractInfo', param).then((res) => {
                let result = res.data.response;
                let code = res.data.code;
                
                if(code == 100){
                    this.statusList = [];
                    let temp = {};
                    temp.addipcnt = result.addipcnt;
                    temp.ip_addrcnt = result.ipaddrcnt;
                    temp.linespeccd = result.linespeccd;
                    temp.linespecnm = result.linespecnm;
                    temp.uploadspd = result.uploadspd;
                    temp.uploadspdnm = result.uploadspdnm;
                    temp.bugauseamt = result.bugauseamt;
                    temp.subsacd = result.subsacd;
                    temp.subsanm = result.subsacdnm;
                    temp.bugaspdcd = result.bugaspdcd;
                    temp.speedcodedesc = result.bugaspdnm;
                    temp.svctypenm = result.svctypenm;
                    temp.termcntrcttypecd = result.termcntrcttypecd;
                    temp.maintagentcd = result.maintagentcd;
                    temp.sellerinfo = '';
                    temp.useamt = '';
                    this.statusList.push(temp)
                }
            })
            .catch(error => {
                console.log(error)
            })
        },
        goOrder () {
            if(this.svcTypeSel == 'FL') {
                if(!this.basicSvcFlag && !this.addSvcFlag) {
                    alert('변경된 정보가 없습니다.')
                    return false
                }
                if(this.termCntrctChgGap == 0 && this.addsvcCntrctChgGap == 0 && this.useAmtSubGap == 0){
                    alert('변경된 정보가 없습니다.')
                    return false
                }
            } else {
                if(!this.locationFlag) {
                    alert('변경된 정보가 없습니다.')
                    return false
                }
            }
            if(!this.checkValidation()) {
                return false
            }
            if(!confirm('상품을 변경신청하시겠습니까?')) {
                return false
            }
            this.postGoOrderSubscriptionReceipt()
        },
        checkValidation() {
			if(this.comNm == '') {
				alert('신청자 이름을 입력해주세요.')
				this.$refs.comNm.focus()
				return false
			}
			if(this.comTel == '') {
				alert('신청자 연락처를 입력해주세요.')
				this.$refs.comTel.focus()
				return false
			}
			if(this.comEmail == '') {
				this.$refs.comEmail.focus()
				alert('신청자 이메일을 입력해주세요.')
				return false
			}
            if(!this.authFlag){
                this.$refs.appDiv.focus()
                alert('본인 인증을 해주세요.')
                return false
            }
            return true
        },
        async postGoOrderSubscriptionReceipt() {
            // this.termCntrctChgGap = 0;
            let param = {
                tokenId: sessionStorage.getItem("accessToken")
                , userId: sessionStorage.getItem('userId')
                // 신청유형 변경 02
                , acptType: '02'
                // 구분
                // , custNoTypeNm : this.custNoType == '3' ? '개인사업' : '법인사업'
                // , custNoTypeNm : this.partyDetailTypeCd == '3' ? '개인사업' : '법인사업'
                , custNoTypeNm : this.custNoTypeNm
                // 고객유형
                , custNoType: this.custNoType
                // 고객정보의 custId: 사업자번호
                , custNo: this.partyDetailTypeCd == '8' || this.partyDetailTypeCd == '08' || this.partyDetailTypeCd == '60'? this.ownerContactInfo.partyIdNumber1 : this.ownerContactInfo.partyIdNumber2
                // 법인명(고객명)
                , bizrCrprNm: this.userInfo.length == 0 ? '' : this.userInfo[0].corpNm
                // 법인등록번호(생년월일)
                , crprNo: this.userInfo.length == 0 ? '' : this.userInfo[0].corpNo
                // 대표자명
                , aplcntNm: this.userInfo.length == 0 ? '' : this.userInfo[0].reprNm
                // 사업자등록번호
                , bizrRgstNo: this.userInfo.length == 0 ? '' : this.userInfo[0].custIdfyNo
                // 전산담당자 이름
                , comNm : this.comNm
                // 전산담당자 연락처
                , comTel : this.comTel
                // 전산담당자 이메일
                , comEmail : this.comEmail
                , aplcntRltnNm: '본인신청'
                // 신청구분 - 본인신청01/대리신청02
                , aplcntRltn: '01'
                // 설치장소 상세주소
                , addrRef: this.locationFlag ? this.detailAddr : ''
                // 빌딩정보의 dongcode
                , dongCd: this.locationFlag ? this.addrInfoSel.dongcode : ''
                // bldg list의 addrtype '일반' or '산' (bldg-list-info api 에서 선택해 올때 값 넣어주셔야 합니다)"
                , addrNoType: this.locationFlag ? this.buildingInfoSel.addr_no_type : ''
                // bldg list addr num // 18 (bldg-list-info api 에서 선택해 올때 값 넣어주셔요)"
                , addrNo: this.locationFlag ? this.buildingInfoSel.addr_no : ''
                // 19 (bldg-list-info api 에서 선택해 올때 값 넣어주셔요)"
                , addrHo: this.locationFlag ? this.buildingInfoSel.addr_ho : ''
                // bldg list 정보의 bldgsvrcode
                , addrBldgId: this.locationFlag ? this.buildingInfoSel.bldgsvrcode : ''
                // 청구정보ID-납부정보
                , billId: '00000000000'
                // 가입유형-기본 서비스 정보 ( -신규신청시 빈값)
                , saId: this.dataSel.scn
                // 서비스명
                , svcTypeCd: this.dataSel.svc_type_cd
                // 다운로드속도
                , lineSpecCd: this.downloadSpcCdTemp
                // 업로드속도
                , uploadSpd: this.uploadSpcCdTemp
                // 추가IP수
                , addIpCnt : this.addIpCntTemp
                // 기본제공IP수
                , ipAddrCnt : this.statusListClone.ip_addrcnt
                // 계약기간-기본 서비스 정보
                , termCntrctTypeCd: ''
                // 대여장비
                , subEquipCd: '' 
                // 협력사
                , maintAgentCd: this.statusListClone.maintagentcd == null ? '' : this.statusListClone.maintagentcd
                // 개통희망일
                , completedResvDateHh : this.completedResvDateHh.replaceAll('/','')
                // 변경 적용 기간 종료일
                , cntrctEndResvDate : this.cntrctEndResvDateCheck ? '' : this.cntrctEndResvDate.replaceAll('/','')
                // cloud direct(기본서비스)-부가서비스정보
                , subSaCd: this.cloudDirectFlag ? this.cloudDirectInfoSel : ''
                // cloud direct 속도
                , bugaSpdCd: this.cloudDirectSpeedSel
                // 부가서비스 없으면 null로
                , bugaUseAmt : this.useAmtSubGap
                // 신규청약 (-remark + / + com_nm + / + com_tel + / + com_email)
                , remark: '변경'
                // 판매자 / 고객 (가입유형) / KT방배지점 (판매자영업점명) / asb (판매자 ID)
                , sellerInfo: this.statusListClone.sellerinfo
                //  가입유형-판매자정보
                , selType: ''
                // 판매자(영업점)명-판매자정보
                , selNm: ''
                // 판매자ID-판매자정보
                , selId: ''
                // 신청자 집 전화 번호
                , aplcntTelNo: this.ownerContactInfo.phoneNumber
                // 신청자 휴대폰 번호
                , aplcntMobileNo: this.ownerContactInfo.mobileNumber
                // 청구정보의 custId 고객정보 조회시 cust_ind 배열중 하나
                , custInd: this.userInfo.length == 0 ? '' : this.userInfo[0].custId
                // 명의자명 고객정보
                , nflCustNm: this.userInfo.length == 0 ? '' : this.userInfo[0].custNm
                // 기가 오피스 : 15 고정 : 12 Flexline : 16
                , prdcType: this.dataSel.svctypecodedesc.substr(0,1) == 'F' ? '16' : '15'
                // 전체총액 숫자로
                , useAmt: this.termCntrctChgGap
                // 연락 email주소
                , cntcEmailAddr: this.ownerContactInfo.email
                // 1 -처리상태코드
                , trtmStatCd: '1'
                // 벤더사-단말모델코드"
                , equipGrpCd: ''
                // GO센터명
                , goOfcName: ''
                // 고정IP 협정요금
                , ipUseAmt: this.addsvcCntrctChgGap
                // IP 협정요금 부과여부" // 여 1, 부 null
                , ipChargeCd: this.addIpCntTemp != '0' ? '1' : ''
                // 설치비면제여부 3년이상약정시 면제
                , nrcExemptRsnCd: ''
                , svcNo: this.dataSel.svcnum
                // 업로드 속도 이용료
				, uploadCost : this.uploadCost

                // Email 발송을 위한 추가
                // 설치주소
                , fullAddr : this.dataSel.addressNoMaking

                , termCntrctChg: 0
                , svcTypeNm: this.dataSel.svctypecodedesc
                , acptTypeNm: '변경'
                , prdcTypeNm : this.dataSel.svctypecodedesc.substr(0,1) == 'F' ? 'Flexline' : 'GiGA office'
                , partnerInfoNmSel: this.dataSel.ascompany.ascompanyname
                , equipGroupInfoNmSel: this.dataSel.ascompany.routervendormodeldesc
                , lineSpeedInfoNmSel: this.lineSpeedInfoNmTemp
                , uploadSpeedInfoNmSel: this.uploadSpeedNmTemp
                , termCntrctTypeCdNmSel: this.dataSel.contractperiodtypecodedesc
                , cntrctInstChg: 0
                , cntrctInstChgSub: this.cntrctInstChgSubTemp
                , equipUseChgSub: this.equipUseChgSubTemp
                , cloudDirectInfoNmSel:this.cloudDirectFlag ? this.subSaNm : ''
                , cloudDirectSpeedNmSel: this.cloudDirectSpeedNmSel
                , otpAuthYn: 'Y'
                , rentSwitchChg: 0

                // 인증번호 SEQ
                , seq: this.seq
                , cdmCustNoType: this.cdmCustNoType
            };
            // console.log('param : ' ,param)
            if(this.termCntrctChgGap != 0){
                let restoreReservation = null;
                if(!this.cntrctEndResvDateCheck){
                    let temp = this.cntrctEndResvDate.split('/')
                    const startDateTemp = new Date(temp[0], temp[1] - 1 ,temp[2])
                    const startDate = new Date(startDateTemp.setDate(startDateTemp.getDate() + 1))
                    const date = new Date( + startDate + this.timeZone ).toISOString().split('T')[0]
                    
                    restoreReservation = {
                        tokenId: sessionStorage.getItem("accessToken")
                        , userId: sessionStorage.getItem('userId')
                        // 신청유형 변경 02
                        , acptType: '02'
                        // 구분
                        // , custNoTypeNm : this.custNoType == '3' ? '개인사업' : '법인사업'
                        , custNoTypeNm : this.custNoTypeNm
                        // 고객유형
                        , custNoType: this.custNoType
                        // 고객정보의 custId: 사업자번호
                        // , custNo: this.partyDetailTypeCd == '8'? this.ownerContactInfo.partyIdNumber1 : this.ownerContactInfo.partyIdNumber2
                        , custNo: this.partyDetailTypeCd == '8' || this.partyDetailTypeCd == '08' || this.partyDetailTypeCd == '60' ? this.ownerContactInfo.partyIdNumber1 : this.ownerContactInfo.partyIdNumber2
                        // 법인명(고객명)
                        , bizrCrprNm: this.userInfo.length == 0 ? '' : this.userInfo[0].corpNm
                        // 법인등록번호(생년월일)
                        , crprNo: this.userInfo.length == 0 ? '' : this.userInfo[0].corpNo
                        // 대표자명
                        , aplcntNm: this.userInfo.length == 0 ? '' : this.userInfo[0].reprNm
                        // 사업자등록번호
                        , bizrRgstNo: this.userInfo.length == 0 ? '' : this.userInfo[0].custIdfyNo
                        // 전산담당자 이름
                        , comNm : this.comNm
                        // 전산담당자 연락처
                        , comTel : this.comTel
                        // 전산담당자 이메일
                        , comEmail : this.comEmail
                        , aplcntRltnNm: '본인신청'
                        // 신청구분 - 본인신청01/대리신청02
                        , aplcntRltn: '01'
                        // 설치장소 상세주소
                        , addrRef: this.locationFlag ? this.detailAddr : ''
                        // 빌딩정보의 dongcode
                        , dongCd: this.locationFlag ? this.addrInfoSel.dongcode : ''
                        // bldg list의 addrtype '일반' or '산' (bldg-list-info api 에서 선택해 올때 값 넣어주셔야 합니다)"
                        , addrNoType: this.locationFlag ? this.buildingInfoSel.addr_no_type : ''
                        // bldg list addr num // 18 (bldg-list-info api 에서 선택해 올때 값 넣어주셔요)"
                        , addrNo: this.locationFlag ? this.buildingInfoSel.addr_no : ''
                        // 19 (bldg-list-info api 에서 선택해 올때 값 넣어주셔요)"
                        , addrHo: this.locationFlag ? this.buildingInfoSel.addr_ho : ''
                        // bldg list 정보의 bldgsvrcode
                        , addrBldgId: this.locationFlag ? this.buildingInfoSel.bldgsvrcode : ''
                        // 청구정보ID-납부정보
                        , billId: '00000000000'
                        // 가입유형-기본 서비스 정보 ( -신규신청시 빈값)
                        , saId: this.dataSel.scn
                        // 서비스명
                        , svcTypeCd: this.dataSel.svc_type_cd
                        // 다운로드속도
                        , lineSpecCd: this.reservationLineSpecCd
                        // 업로드속도
                        , uploadSpd: this.reservationUploadSpd
                        // 추가IP수
                        , addIpCnt : this.addIpCntTemp
                        // 기본제공IP수
                        , ipAddrCnt : this.statusListClone.ip_addrcnt
                        // 계약기간-기본 서비스 정보
                        , termCntrctTypeCd: ''
                        // 대여장비
                        , subEquipCd: '' 
                        // 협력사
                        , maintAgentCd: this.statusListClone.maintagentcd == null ? '' : this.statusListClone.maintagentcd
                        // 개통희망일
                        , completedResvDateHh : date.replaceAll('-','')
                        // 변경 적용 기간 종료일
                        , cntrctEndResvDate : ''
                        // cloud direct(기본서비스)-부가서비스정보
                        , subSaCd: this.cloudDirectFlag ? this.cloudDirectInfoSel : ''
                        // cloud direct 속도
                        , bugaSpdCd: this.cloudDirectSpeedSel
                        // 부가서비스 없으면 null로
                        , bugaUseAmt : this.useAmtSubGap
                        // 신규청약 (-remark + / + com_nm + / + com_tel + / + com_email)
                        , remark: '변경'
                        // 판매자 / 고객 (가입유형) / KT방배지점 (판매자영업점명) / asb (판매자 ID)
                        , sellerInfo: this.statusListClone.sellerinfo
                        //  가입유형-판매자정보
                        , selType: ''
                        // 판매자(영업점)명-판매자정보
                        , selNm: ''
                        // 판매자ID-판매자정보
                        , selId: ''
                        // 신청자 집 전화 번호
                        , aplcntTelNo: this.ownerContactInfo.phoneNumber
                        // 신청자 휴대폰 번호
                        , aplcntMobileNo: this.ownerContactInfo.mobileNumber
                        // 청구정보의 custId 고객정보 조회시 cust_ind 배열중 하나
                        , custInd: this.userInfo.length == 0 ? '' : this.userInfo[0].custId
                        // 명의자명 고객정보
                        , nflCustNm: this.userInfo.length == 0 ? '' : this.userInfo[0].custNm
                        // 기가 오피스 : 15 고정 : 12 Flexline : 16
                        , prdcType: this.dataSel.svctypecodedesc.substr(0,1) == 'F' ? '16' : '15'
                        // 전체총액 숫자로
                        , useAmt: 0
                        // 연락 email주소
                        , cntcEmailAddr: this.ownerContactInfo.email
                        // 1 -처리상태코드
                        , trtmStatCd: '1'
                        // 벤더사-단말모델코드"
                        , equipGrpCd: ''
                        // GO센터명
                        , goOfcName: ''
                        // 고정IP 협정요금
                        , ipUseAmt: this.addsvcCntrctChgGap
                        // IP 협정요금 부과여부" // 여 1, 부 null
                        , ipChargeCd: this.addIpCntTemp != '0' ? '1' : ''
                        // 설치비면제여부 3년이상약정시 면제
                        , nrcExemptRsnCd: ''
                        , svcNo: this.dataSel.svcnum

                        // Email 발송을 위한 추가
                        // 설치주소
                        , fullAddr : this.dataSel.addressNoMaking

                        , termCntrctChg: 0
                        , svcTypeNm: this.dataSel.svctypecodedesc
                        , acptTypeNm: '변경'
                        , prdcTypeNm : this.dataSel.svctypecodedesc.substr(0,1) == 'F' ? 'Flexline' : 'GiGA office'
                        , partnerInfoNmSel: this.dataSel.ascompany.ascompanyname
                        , equipGroupInfoNmSel: this.dataSel.ascompany.routervendormodeldesc
                        , lineSpeedInfoNmSel: this.reservationLineSpecInfoNmSel
                        , uploadSpeedInfoNmSel: this.reservationUploadSpdInfoNmSel
                        , termCntrctTypeCdNmSel: this.dataSel.contractperiodtypecodedesc
                        , cntrctInstChg: 0
                        , cntrctInstChgSub: this.cntrctInstChgSubTemp
                        , equipUseChgSub: this.equipUseChgSubTemp
                        , cloudDirectInfoNmSel:this.cloudDirectFlag ? this.subSaNm : ''
                        , cloudDirectSpeedNmSel: this.cloudDirectSpeedNmSel
                        , otpAuthYn: 'Y'
                        , rentSwitchChg: 0

                        // 인증번호 SEQ
                        , seq: this.seq
                        , cdmCustNoType: this.cdmCustNoType
                    };
                }

                const params = {
                    reservation: param
                    , restoreReservation:restoreReservation
                }

                // console.log('params : ', params)

                if((this.lineSpeedInfoTemp != this.initDownloadSpcCdTemp
                    || this.uploadSpeedInfoTemp != this.initUploadSpcCdTemp
                    || this.addIpCntTemp != this.initAddIpCntTemp) 
                    && this.termCntrctChgGap == 0) {
                        alert('메뉴 조회 실패 하였습니다. 예약변경[속도] 희망일 메뉴 재조회 합니다.')
                        window.location.reload()
                        return false
                }

                this.$store.dispatch("gigaoffice/postGoOrderSubscriptionReceiptReservation", params).then(res => {
                    if(res.data.code == '100' || res.data.code == '400') {
                        alert('서비스 변경 신청이 완료되었습니다.')
                        this.$router.push('/')
                    }else{
                        alert(res.data.message)
                        this.$router.push('/')
                    }
                }).catch(error => {
                    console.log(error);
                })
            }else{
                const formData = new FormData();
                formData.append("flexLineInsertReqVo", new Blob([JSON.stringify(param)], { type: "application/json" }));
                this.$store.dispatch("gigaoffice/postGoOrderSubscriptionReceipt", formData).then(res => {
                    if(res.data.code == '100' || res.data.code == '400') {
                        alert('서비스 변경 신청이 완료되었습니다.')
                        this.$router.push('/')
                    }else{
                        alert(res.data.message)
                        this.$router.push('/')
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
        },
        // 회선속도코드 조회
		async getLineSpeedList(svcTypeCd) {
			const res = await this.getComCodeList('LS')
			var result = res.data.response.results
            this.lineSpeedInfoCal = result
            this.lineSpeedInfoCal = this.lineSpeedInfoCal.find((item) => item.code_cd == this.statusListClone.uploadspd)
            // GIGAoffice Standard : 1G
            if(svcTypeCd == '31') {
                this.lineSpeedInfo = result.filter((item) => item.code_cd == '39')
                // this.lineUploadSpeedInfo = result.filter((item) => item.code_cd == '39')
            // GIGAoffice Compact : 500M
            } else if (svcTypeCd == '32') {
                this.lineSpeedInfo = result.filter((item) => item.code_cd == '95')
                // this.lineUploadSpeedInfo = result.filter((item) => item.code_cd == '95')
            // GIGAoffice Simple : 200M
            } else if (svcTypeCd == '34') {
                this.lineSpeedInfo = result.filter((item) => item.code_cd == '80')
                // this.lineUploadSpeedInfo = result.filter((item) => item.code_cd == '80')
            // Flexline Basic: 100M, 200M, 500M, 1G
            } else if (svcTypeCd == '11') {
                this.lineSpeedInfo = result.filter((item) => item.code_cd == '3A' || item.code_cd == '80' || item.code_cd == '95' || item.code_cd == '39')
                // this.lineUploadSpeedInfo = result.filter((item) => item.code_cd == '3A' || item.code_cd == '80' || item.code_cd == '95' || item.code_cd == '39')
            // Flexline Premium: 200M ~ 1G
            } else if (svcTypeCd == '20') {
                this.lineSpeedInfo = result.filter((item) => item.code_cd != '3A')
                // this.lineUploadSpeedInfo = result.filter((item) => item.code_cd != '3A')
            // Flexline Premium+: 100M ~ 1G
            } else if(svcTypeCd == '30') {
                this.lineSpeedInfo = result
                // this.lineUploadSpeedInfo = result
            }
        },
        // 상품정보
        async goProductCostInfo () {
            try {
                const payload = {
                token_id: sessionStorage.getItem('accessToken')
                }
                const res = await this.$store.dispatch('gigaoffice/getGoProductCostInfo', payload)
                this.productCostInfo = res.data.response.results
            } catch (err) {
                console.log(err)
            }
        },
        // CloudDirect 조회
        async getCloudDirectList() {
            const res = await this.getComCodeList('CD')
            this.cloudDirectInfo = res.data.response.results
        },
        // CloudDirect 속도 조회
        async getCloudDirectSpeedList() {
            const res = await this.getComCodeList('BS')
            this.cloudDirectSpeed = res.data.response.results
        },
        async goProductInfo () {
            try {
                const payload = {
                    token_id: sessionStorage.getItem('accessToken')
                }
                const res = await this.$store.dispatch('gigaoffice/getGoProductInfo', payload)
                this.productInfo = res.data.response.results
            } catch (err) {
                console.log(err)
            }
        },
        // 주소 검색
        async getCustAddressList () {
            const payload = {
                token_id: sessionStorage.getItem('accessToken'),
                dong: this.dong
            }
            await this.$store.dispatch('gigaoffice/getCustAddressList', payload).then(res => {
                // 동 세팅
                this.addrList = res.data.response.results
                // 초기화
                this.bldgname = ''
            })
            .catch(err => {
                console.log(err)
            })
        },
        addrInfoTemp(addrInfo) {
			this.addrInfo = addrInfo
			setTimeout(() => {
				this.$refs.building.focus()
			}, 1)
			this.popAddrInfo = false
        },
        buildingInfoTemp(buildingInfo) {
            this.buildingInfo = buildingInfo
		},
        // 건물 검색
		async getCustAddressBldgList () {
			const payload = {
				token_id: sessionStorage.getItem('accessToken'),
				bldgname: this.bldgname,
				addrseq: this.addrInfo.addrseq
			}
			await this.$store.dispatch('gigaoffice/getCustBldgList', payload).then(res => {
				this.buildingList = res.data.response.results
			})
			.catch (err => {
                console.log(err)
            })
        },
        // 공통코드 조회
		async getComCodeList(group_cd, code_cd) {
			let payload = {
				group_cd : group_cd,
				code_cd : code_cd 
			}
			let response = {}
			await this.$store.dispatch('gigaoffice/getComCodeList', payload).then(res => {
				response = res
			})
			.catch(err => {
				console.log(err)
			})
			return response
		},
        // ip 개수 계산
		getaddIpCntTemp(type) {
			let minIpCnt = 0;
			let maxIpCnt = 0;
			let vIpCnt = 0;
			switch(this.dataSel.svc_type_cd) {
                // basic
				case '11' :
                    minIpCnt = 1
                    maxIpCnt = 3
                    vIpCnt = 1
                    break
                // premium / premium+
                case '20' :
					minIpCnt = 16
					// maxIpCnt = 256
					maxIpCnt = 64
					vIpCnt = 16
					break;
				case '30' :
					if(this.lineSpeedInfoTemp == '3A' || this.lineSpeedInfoTemp == '80' || this.lineSpeedInfoTemp == '85' || this.lineSpeedInfoTemp == '90'){
						maxIpCnt = 64
					}else{
						maxIpCnt = 128
					}
					minIpCnt = 16
					// maxIpCnt = 256
					vIpCnt = 16
					break;
                // standard /compact
                case '31' :
                case '32' :
                    minIpCnt = 8
                    maxIpCnt = 256
                    // PLUS
                    if (type == '+') {
                        if (this.addIpCntTemp < maxIpCnt) {
                            this.addIpCntTemp = Number(this.addIpCntTemp) + 2
                        }
                    // MINUS
                    } else {
                        if(this.addIpCntTemp > minIpCnt) {
                            this.addIpCntTemp = Number(this.addIpCntTemp) / 2
                        } else {
                            return false
                        }
                    }
                    break
                // simple
                case '34' :
                    break
            }
            if(type == '+') {
                if(this.addIpCntTemp < maxIpCnt ) {
                this.addIpCntTemp = Number(this.addIpCntTemp) + Number(vIpCnt)
                }
            } else {
                if(this.addIpCntTemp > 0) {
                this.addIpCntTemp = Number(this.addIpCntTemp) - Number(vIpCnt)
                }
            }
            this.getAddIpCost()
            this.getCost()
		},
        // ip당 가격
        async getGoSubProductInfo () {
            try {
                const payload = {
                token_id: sessionStorage.getItem('accessToken')
                }
                const res = await this.$store.dispatch('gigaoffice/getGoSubProductInfo', payload)
                this.subProductInfo = res.data.response.results
            } catch (err) {
                console.log(err)
            }
        },
        async getAddIpCost() {
            if(this.svctype_sel == 'GO') {
                return false
            }
            var addipcnt_before = 0
            if(typeof this.statusList[0] == 'undefined') {
                addipcnt_before = 0
            } else {
                addipcnt_before = this.statusList[0].addipcnt
            }

            await this.subProductInfo.forEach(item => {
                if (item.svc_type_cd == this.dataSel.svc_type_cd) {
                    //기존 추가 IP가격
                    if (item.addsvc_req_cnt == addipcnt_before) {
                        this.addsvcCntrctChg_before = item.addsvc_cntrct_chg
                    } else if(addipcnt_before == 0){
                        this.addsvcCntrctChg_before = 0
                    }
                    // 변경될 추가 IP가격
                    if (item.addsvc_req_cnt == this.addIpCntTemp) {
                        this.addsvcCntrctChgTemp = item.addsvc_cntrct_chg
                    } else if (this.addIpCntTemp == 0) {
                        this.addsvcCntrctChgTemp = 0
                    }
                }
            })
		},
        async getCost (type) {
            //기존 가격 세팅
            var termcntrcttypecd = ''
            if(this.dataSel.contractperiodtypecodedesc == '기간없음') {
                termcntrcttypecd = 'EQ0Y'
            } else if(this.dataSel.contractperiodtypecodedesc == '2년') {
                termcntrcttypecd = 'EQ2Y'
            } else if(this.dataSel.contractperiodtypecodedesc == '3년') {
                termcntrcttypecd = 'EQ3Y'
            } else if(this.dataSel.contractperiodtypecodedesc == '4년') {
                termcntrcttypecd = 'EQ4Y'
            } else {
                termcntrcttypecd = 'EQ5Y'
            }
            if(type == 'init') {
                this.svcTypeCd = this.dataSel.svc_type_cd
                this.termCntrctTypeCd = termcntrcttypecd
                this.lineSpeedInfoSel = this.dataSel.line_spec_cd
                await this.productCostInfo.forEach(item => {
                    if (item.svc_type_cd == this.dataSel.svc_type_cd) {
                        if (item.term_cntrct_type_cd == this.termCntrctTypeCd) {
                            if(this.dataSel.svc_type_cd == '11' || this.dataSel.svc_type_cd == '20' || this.dataSel.svc_type_cd == '30') {
                                if (item.line_spec_cd == this.dataSel.line_spec_cd) {
                                    this.cntrctInstChgBefore = item.cntrct_inst_chg
                                    this.equipUseChgBefore = item.equip_use_chg
                                    this.termCntrctChgBefore = item.term_cntrct_chg
                                    this.svcTotalChgBefore = item.svc_total_chg
                                }
                            } else {
                                this.cntrctInstChgBefore = item.cntrct_inst_chg
                                this.equipUseChgBefore = item.equip_use_chg
                                this.termCntrctChgBefore = item.term_cntrct_chg
                                this.svcTotalChgBefore = item.svc_total_chg
                            }
                        }
                    }
                })
            //변경 되는 요금
            } else {
                await this.productCostInfo.forEach(item => {
                    if (item.svc_type_cd == this.dataSel.svc_type_cd) {
                        if (item.term_cntrct_type_cd == this.termCntrctTypeCd) {
                            if(this.dataSel.svc_type_cd == '11' || this.dataSel.svc_type_cd == '20' || this.dataSel.svc_type_cd == '30') {
                                if (item.line_spec_cd == this.lineSpeedInfoTemp) {
                                    this.cntrctInstChgTemp = item.cntrct_inst_chg
                                    this.equipUseChgTemp = item.equip_use_chg
                                    this.termCntrctChgTemp = item.term_cntrct_chg
                                    this.svcTotalChgTemp = item.svc_total_chg
                                }
                            } else {
                                this.cntrctInstChgTemp = item.cntrct_inst_chg
                                this.equipUseChgTemp = item.equip_use_chg
                                this.termCntrctChgTemp = item.term_cntrct_chg
                                this.svcTotalChgTemp = item.svc_total_chg
                            }
                        }
                    }
                })
            }
            this.getSum()
        },
        // cloud direct 상품별 가격
		async getCloudDirectCostInfo () {
            if(this.svcTypeSel == 'GO') {
                return false
            }
            try {
                const payload = {
                    SvcTypeCd: this.cloudDirectInfoSel == '' ? '2520' : this.cloudDirectInfoSel
                    // SvcTypeCd: this.cloudDirectInfoSel == '' ? this.statusList[0].subsacd : this.cloudDirectInfoSel
                }
                const res = await this.$store.dispatch('gigaoffice/getCloudDirectCostInfo', payload)
                this.cloudDirectCostInfo = res.data.response.list
            } catch (err) {
                console.log(err)
            }
		},
        // clouddirect 가격
		getSubCost() {
            if(this.svcTypeSel == 'GO') {
                return false
            }
            if(this.cloudDirectCostInfo != ''){
                this.cloudDirectCostInfo.forEach(item => {
                    if(item.lineSpecCd == this.cloudDirectSpeedSel){
                        this.cntrctInstChgSubTemp = parseInt(item.cntrctInstChg)
                        this.equipUseChgSubTemp = parseInt(item.equipUseChg)
                        this.svcTotalChgSubTemp = parseInt(item.svcTotalChg)
                    }
                })
            }

            this.getSum()
        },
        getSum() {
			// ICIS  연동 요금 3가지 (중요)
			// 1) 기본 협정요금 useAmt: “기본서비스 + 공급장비 업그레이드 + 업로드 속도 변경 요금 (Premium만 해당)” 합한 값
			// 2) 추가 IP 요금 addsvcCntrctChg
			// 3) Cloud Direct 요금 equipUseChg_sub
            if(this.svcTypeSel == 'GO') {
                return false
            }
            if(!this.svcTypeCd) return false
            if(!this.lineSpeedInfoSel) return false
            this.useAmtGap = this.termCntrctChgGap + this.addsvcCntrctChgGap + this.cntrctInstChgAfter + this.useAmtSubGap
            // 업로드 속도 변경요금
			this.useAmtGap += parseInt(this.uploadCost)
		},
        cloudDirectChange(e, boolean) {
			if(boolean) {
				if(this.dataSel.svc_type_cd == '11' || this.dataSel.svc_type_cd == '20') {
					this.cloudDirectFlag = true
				}
			//선택안함
			} else {
				this.cloudDirectFlag = false
				this.cloudDirectInfoSel = ''
				this.cloudDirectSpeedSel = ''
				this.$refs.cldSvc.innerHTML = `<span class="fcGy">선택하세요</span>`
				this.$refs.cldSvcSpd.innerHTML = `<span class="fcGy">최대 속도</span>`
				this.useAmtSubGap = 0
                this.cntrctInstChgSubTemp = 0
                this.equipUseChgSubTemp = 0
                this.svcTotalChgSubTemp = 0
                this.useAmtSubBefore = 0

                this.getSubCost()
			}
		},
        // 팝업 오픈
		async openPopup(type) {
            switch (type) {
                case 'locationInfo' :
                    this.popLocationInfo = true
                    this.locationFlag = false
                    setTimeout(() => {
                        this.$refs.dong.focus()
                    }, 1)
                    break
                case 'addrInfo' :
                    if (this.dong === '') {
                        alert('검색을 원하시는 동 이름을 입력해주세요.')
                        this.$refs.dong.focus()
                        return false
                    }
                    await this.getCustAddressList()
                    this.popAddrInfo = true
                    this.addrFlag = false
                    setTimeout(() => {
                        this.$refs.dong2.focus()
                    }, 1)
                    break
                case 'buildingInfo' :
                    if (this.dong == '') {
                        alert('주소 검색 후에 가능합니다.')
                        return false
                    }
                    if (this.bldgname == '') {
                        alert('검색을 원하시는 건물 이름을 입력해주세요.')
                        return false
                    }
                    this.detailAddr = ''
                    this.popBuildingInfo = true
                    this.buildingFlag = false
                    await this.getCustAddressBldgList()
                    break;
                case 'basicSvcInfo' :
                    this.popBasicSvcInfo = true
                    this.basicSvcFlag = false
                    break
                case 'addSvcInfo' :
                    this.popAddSvcInfo = true
                    this.addSvcFlag = false
                    break
            }
        },
        // 팝업 닫기
		closePopup(type) {
            switch (type) {
                case 'locationInfo' :
                    if(this.addrInfo == '') {
                        alert('변경된 주소정보가 없습니다.')
                        return false
                    }
                    if(this.buildingInfo == '') {
                        alert('변경된 건물정보가 없습니다.')
                        return false
                    }
                    if(this.detailAddr == '') {
                        alert('입력된 상세주소가 없습니다.')
                        return false
                    }
                    this.addrInfoSel = this.addrInfo
                    this.buildingInfoSel = this.buildingInfo
                    this.fullAddr = `${this.buildingInfo.addr1+this.buildingInfo.addr2+this.detailAddr}`
                    this.dataSel.addressNoMaking = this.fullAddr
                    this.popLocationInfo = false
                    this.locationFlag = true
                    break
                case 'addrInfo' :
                    if (this.addrInfo === '') {
                        alert('검색 결과를 선택해주세요.')
                        return false
                    }
                    this.addrInfoSel = this.addrInfo
                    this.popAddrInfo = false
                    this.addrFlag = true
                    setTimeout(() => {
                        this.$refs.building.focus()
                    }, 1)
                    break
                case 'buildingInfo' :
                    if (this.buildingInfo_temp === '') {
                        alert('검색 결과를 선택해주세요.')
                        return false
                    }
                    if(this.detailAddr == '') {
                        alert('상세주소를 입력해주세요.')
                        return false
                    }
                    this.buildingInfoSel = this.buildingInfo_temp
                    this.popBuildingInfo = false
                    this.buildingFlag = true
                    break
                case 'basicSvcInfo' :
                    // 변수 초기화
                    this.downloadSpcCdTemp = ''
                    this.uploadSpcCdTemp = ''
                    this.addIpCnt = 0

                    if(this.lineSpeedInfoTemp == this.initDownloadSpcCdTemp
                        && this.uploadSpeedInfoTemp == this.initUploadSpcCdTemp
                        && this.addIpCntTemp == this.initAddIpCntTemp) {
                            alert('변경된 정보가 없습니다.')
                            return false
                    }

                    if(this.lineSpeedInfoTemp != this.initDownloadSpcCdTemp){
                        this.downloadSpcCdTemp = this.lineSpeedInfoTemp
                    }
                    if(this.uploadSpeedInfoTemp != this.initUploadSpcCdTemp){
                        this.uploadSpcCdTemp = this.uploadSpeedInfoTemp
                    }
                    if(this.addIpCntTemp != this.initAddIpCntTemp){
                        this.addIpCnt = this.addIpCntTemp
                    }

                    // //속도 
                    this.dataSel.line_spec_cd = this.lineSpeedInfoTemp
                    this.dataSel.speedcodedesc = this.lineSpeedInfoNmTemp
                    this.statusListClone.linespeccd = this.lineSpeedInfoTemp
                    this.statusListClone.linespecnm = this.lineSpeedInfoNmTemp
                    this.statusListClone.uploadspd = this.uploadSpeedInfoTemp
                    this.statusListClone.uploadspdnm = this.uploadSpeedNmTemp
                    // //IP 
                    this.statusListClone.addipcnt = this.addIpCntTemp

                    //기본요금
                    this.svcTotalChgAfter = this.svcTotalChg_temp
                    
                    //IP변경 차액
                    this.addsvcCntrctChgGap = this.addsvcCntrctChgTemp - this.addsvcCntrctChg_before
                    //기본서비스(다운로드속도) 변경 차액
                    this.termCntrctChgGap = this.termCntrctChgTemp - this.termCntrctChgBefore
                    //업로드속도 변경 차액
                    this.popBasicSvcInfo = false
                    this.basicSvcFlag = true
                    this.useAmtFlag = true
                    break
                case 'addSvcInfo' :
                    this.bugaSpdCdTemp = 0
                    // Cloud
                    if((this.svcTotalChgSubTemp == this.statusListClone.bugauseamt) && (this.cloudDirectInfoSel == this.statusListClone.subsacd)) {
                        alert('기존 상품과 같습니다.')
                        return false
                    }

                    this.bugaSpdCdTemp = this.cloudDirectSpeedSel

                    this.statusListClone.subsanm = this.subSaNm
                    this.statusListClone.subsacd = this.cloudDirectSpeedSel
                    this.statusListClone.speedcodedesc = this.tempSpeedCodeDesc
                    this.equipUseChg_after = this.equipUseChg_temp
                    this.svcTotalChg_sub_after = this.svcTotalChgSubTemp
                    //부가서비스 변경 차액
                    // this.useAmtSubGap = this.svcTotalChgSubTemp - this.useAmtSubBefore
                    this.useAmtSubGap = this.equipUseChgSubTemp - this.useAmtSubBefore
                    this.popAddSvcInfo = false
                    this.addSvcFlag = true
                    break
            }
        },
        async verifySend () {
            this.sendFlag = false
            const params = {}
            params.userId = sessionStorage.getItem("userId")
            if(this.appDiv == '01'){
                params.verify = '2'
                params.email = this.comEmail
            }else{
                params.verify = '1'
                params.phoneNumber = this.comTel
            }
			const res = await this.$store.dispatch('gigaoffice/postVerifySend', params)
            if(res.data.returncode === '1'){
                document.querySelectorAll('input[name="division3"]').forEach(item => {
					item.disabled = true
				})
                this.timer()
                this.sendFlag = true
                
                if(this.appDiv == '01'){
                    alert('EMAIL로 인증 번호를 전송했습니다')
                }else{
                    alert('핸드폰으로 인증 번호를 전송했습니다')
                }
            }else{
                if(this.appDiv == '01'){
                    alert('EMAIL 주소를 확인해주세요')
                }else{
                    alert('핸드폰 번호를 확인해주세요')
                }
            }

        },
        async verifyCheck () {
            if(this.authNum !== ''){
                this.authFlag = false
                const params = {}
                params.userId = sessionStorage.getItem("userId")
                if(this.appDiv == '01'){
                    params.verify = '2'
                    params.email = this.comEmail
                }else{
                    params.verify = '1'
                    params.phoneNumber = this.comTel
                }
                params.authNo = this.authNum
                const res = await this.$store.dispatch('gigaoffice/postVerifyCheck', params)
                if(res.data.returncode === '1'){
                    alert('인증번호를 확인했습니다')
                    this.authFlag = true
                    document.getElementById('verifyCheck').disabled = true
                    clearInterval(this.timeInterval)
                    this.seq = res.data.seq
                }else{
                    alert('인증번호를 확인해주세요')
                }
            }else{
                alert('인증번호를 확인해주세요')
            }
        },
        timer () {
            let end = 180000
            let min = 3
            let sec = 60

            this.timeInterval = setInterval(function(){
                end = end - 1000
                min = end / (60 * 1000)

                if(sec > 0){
                    sec = sec - 1
                    this.time = Math.floor(min) + ':' + String(sec).padStart(2,'0')
                }
                if(sec === 0){
                    sec = 60
                    
                    if(min === 0){
                        alert('인증번호 입력 시간이 경과했습니다')
                        clearInterval(this.timeInterval)
                        this.authNum = ''
                        this.authFlag = false
                        this.sendFlag = false
                        document.querySelectorAll('input[name="division3"]').forEach(item => {
                            item.disabled = false
                        })
                    }
                }
            }.bind(this) ,1000)
        }
    }
};
</script>
<style>
    h4 {
        font-size: 24px;
        font-weight: 400;
        color: #2E2D59;
        margin: 80px 0 40px 0;
    }
    .fcon.multi input {
        width: 300px;
    }
    .radio.two label {
        width: calc(100% / 2);
    }
    .multi span {
        width: 200px;
        display: inline-block;
        line-height: 52px;
        color: #111;
    }
    .multi label ,.multi a {
        width: 200px;
        margin-left: 32px;
    }
    .checkbox {
        margin-left: 10px;
        display: inline-block;
    }
</style>